<template>
  <v-container
    id="subscribe"
    tag="section"
  >
    <div>
      <v-row>
        <v-col
          cols="12"
        >
          <div 
          class="content_block">
            <p>
              If you’re already paying by credit or debit card for your insurance policy you can do so quickly and easily here by following the steps below.
            </p>
            <p>
              <strong>Please note:</strong> If you're already paying by direct debit, your renewal premium will be taken as usual from the bank account details we hold for you.
            </p>
          </div>
          <h3
          align="center">TO RENEW WITH US USING YOUR DEBIT OR CREDIT CARD, JUST FOLLOW THESE SIMPLE STEPS</h3>
          <v-row>
            <v-col 
            xs="10"
            sm="12"
            md="4"
            lg="4"
            xl="4">
              <div class="info" id="step_1">
                <h4 class="info-title">Step 1</h4>
                <h5 class="info-sub-title">Your Information</h5>
                <p>
                  Enter the following information:
                </p>
                <ul>
                  <li>
                    Insurance type
                  </li>
                  <li>
                    Policy number
                  </li>
                  <li>
                    Surname/Company name
                  </li>
                  <li>
                    Postcode
                  </li>
                </ul>
                <p
                class="info_help">
                  <i>Your policy number can be found on your documents, e.g. BPNL31245654</i>
                </p>
              </div>
            </v-col>
            <v-col
            col="12"
            xs="12"
            sm="6"
            md="4"
            lg="4"
            xl="4">
              <div class="info" id="step_2">
                <h4 class="info-title">Step 2</h4>
                <h5 class="info-sub-title">Check your details</h5>
                <p>
                  Make sure the details we have for you are correct and then proceed to the payment screen.
                </p>
                <p>
                  If you need to make any changes to your cover or personal information, give us a call <span class="phone">0345 601 1050</span> 
                  <!-- Monday - Friday 8am to 7pm, or <br>Saturdays 8am - 2pm. -->
                </p>
              </div>
            </v-col>
            <v-col
            col="12"
            xs="12"
            sm="6"
            md="4"
            lg="4"
            xl="4">
              <div class="info" id="step_3">
                <h4 class="info-title">Step 3</h4>
                <h5 class="info-sub-title">Renew your policy</h5>
                <p>
                  Enter your card details to renew your policy.
                </p>
                <p>
                  Please have your payment card details on hand to complete your renewal. 
                </p>
                <p
                class="info_help">
                  <i>
                    You can renew your policy at any time during your 30 day renewal period
                  </i>
                </p>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            align="center"
            xs="12"
            sm="12"
            md="4"
            lg="4"
            xl="4"
            offset-xs="0"
            offset-sm="0"
            offset-md="4"
            offset-lg="4"
            offset-xl="4"
        >
          <renew />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'HomeSubscribe',
    components: {
      Renew: () => import('@/components/home/Renew'),
    },
  }
</script>
